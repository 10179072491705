<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid grid-list-md>
            <v-row>

                <v-col cols="10" xs="12" sm="12" md="8" lg="10" xl="10">
                    <v-row>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="storegiftlistcategory.stor_glc_codigo" label="Código" outlined></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="storegiftlistcategory.stor_glc_nome" :rules="rules.nomeRules" label="Nome" outlined required></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="storegiftlistcategory.stor_glc_ordem" label="Ordem" outlined></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="storegiftlistcategory.stor_glc_link" label="Link" outlined></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="12" xl="12" class="d-flex flex-row">
                            <v-switch color="lime" v-model="storegiftlistcategory.stor_glc_status" label="Status"></v-switch>
                            <v-switch color="lime" v-model="storegiftlistcategory.stor_glc_show_menu" label="Mostrar no menu"></v-switch>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                            <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                                <v-icon class="mdi mdi-check"></v-icon>{{btnLabel}}
                            </v-btn>
                        </v-col>

                    </v-row>
                </v-col>

                <v-col cols="2" xs="12" sm="12" md="4" lg="2" xl="2">

                    <v-layout row wrap justify-center column>

                        <v-flex v-if="previewIcone || storegiftlistcategory.stor_glc_icone">
                            <v-card>
                                <v-img v-if="previewIcone" :src="previewIcone" aspect-ratio="3"></v-img>
                                <v-img v-if="storegiftlistcategory.stor_glc_icone  && !previewIcone" :src="`${base_url}upload/store_gift_list_category/i/${storegiftlistcategory.stor_glc_icone}`" aspect-ratio="3"></v-img>

                                <v-card-actions class="justify-center">
                                    <v-btn icon>
                                        <v-icon @click="$refs.fileIcone.click()">cloud_upload</v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon @click.prevent="removePreviewIcone">highlight_off</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>

                        <v-flex d-flex justify-center mb-16>
                            <input type="file" ref="fileIcone" style="display:none;" id="fileIcone" @change="onFileIconeChange" accept='image/png' />
                            <v-btn v-if="!previewIcone && !storegiftlistcategory.stor_glc_icone" color="blue-grey" class="btn-upload white--text" @click="$refs.fileIcone.click()">
                                Adicionar Icone
                                <v-icon right dark>cloud_upload</v-icon>
                            </v-btn>
                        </v-flex>

                        <v-flex v-if="previewBanner || storegiftlistcategory.stor_glc_banner" mt-10>
                            <v-card>
                                <v-img v-if="previewBanner" :src="previewBanner" aspect-ratio="3"></v-img>
                                <v-img v-if="storegiftlistcategory.stor_glc_banner && !previewBanner" :src="`${base_url}upload/store_gift_list_category/b/i/${storegiftlistcategory.stor_glc_banner}`" aspect-ratio="3"></v-img>

                                <v-card-actions class="justify-center">
                                    <v-btn icon>
                                        <v-icon @click="$refs.fileBanner.click()">cloud_upload</v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon @click.prevent="removePreviewBanner">highlight_off</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>

                        <v-flex d-flex justify-center>
                            <input type="file" ref="fileBanner" style="display:none;" id="fileBanner" @change="onFileBannerChange" accept='image/jpg' />
                            <v-btn v-if="!previewBanner && !storegiftlistcategory.stor_glc_banner" color="blue-grey" class="btn-upload white--text" @click="$refs.fileBanner.click()">
                                Adicionar Banner
                                <v-icon right dark>cloud_upload</v-icon>
                            </v-btn>
                        </v-flex>

                    </v-layout>

                </v-col>
            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    name: "FormStoreGiftListCategoryComponent",
    created() {
        //this.$store.dispatch('loadProductCategories');
    },
    computed: {
        loadProductCategories() {
            //return this.$store.state.productcategories.items.data;
        }
    },
    props: {
        storegiftlistcategory: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    stor_glc_id: '',
                    stor_glc_codigo: '',
                    stor_glc_nome: '',
                    stor_glc_slug: '',
                    stor_glc_icone: '',
                    stor_glc_banner: '',
                    stor_glc_link: '',
                    stor_glc_ordem: '',
                    stor_glc_status: true,
                    stor_glc_show_menu: true,
                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    data() {
        return {
            valid: true,
            rules: {
                nomeRules: [
                    v => !!v || 'Campo Nome é obrigatório',
                    v => (v && v.length <= 100) || 'Nome precisa ter até 100 Caracteres'
                ],
            },
            uploadIcone: null,
            fileNameIcone: 'Selecione um ícone',
            fileNameBanner: 'Selecione uma imagem',
            previewIcone: null,
            previewBanner: null,
            base_url: URL_BASE
        }
    },
    methods: {
        onSubmit() {

            if (this.$refs.form.validate()) {
                const action = this.update ? 'updateStoreGiftListCategory' : 'storeStoreGiftListCategory';
                const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                const formData = new FormData();

                if (this.uploadIcone !== null) 
                    formData.append('stor_glc_icone', this.uploadIcone);


                if (this.uploadBanner !== null)
                    formData.append('stor_glc_banner', this.uploadBanner);

                formData.append('stor_glc_id', this.storegiftlistcategory.stor_glc_id);
                formData.append('stor_glc_codigo', this.storegiftlistcategory.stor_glc_codigo);
                formData.append('stor_glc_nome', this.storegiftlistcategory.stor_glc_nome);
                formData.append('stor_glc_icone', this.storegiftlistcategory.stor_glc_icone);
                formData.append('stor_glc_banner', this.storegiftlistcategory.stor_glc_banner);
                formData.append('stor_glc_slug', this.storegiftlistcategory.stor_glc_slug);
                formData.append('stor_glc_link', this.storegiftlistcategory.stor_glc_link);
                formData.append('stor_glc_ordem', this.storegiftlistcategory.stor_glc_ordem);
                formData.append('stor_glc_status', this.storegiftlistcategory.stor_glc_status);
                formData.append('stor_glc_show_menu', this.storegiftlistcategory.stor_glc_show_menu);

                this.$store.dispatch(action, formData)
                    .then(() => {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: msg,
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.$router.push({
                            name: 'store-gift-list-categories'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Erro',
                            text: error,
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response.data.errors
                    })
            } else {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Alguns campos requer sua atenção',
                    text: 'Verifique os campos em vermelho',
                    showConfirmButton: true,
                    timer: 6000
                });
            }

        },
        onFileIconeChange(e) {
            let files = e.target.files || e.dataTransfer.files;

            if (!files.length)
                return;

            this.uploadIcone = files[0];

            if (this.uploadIcone) {
                if (this.uploadIcone.type !== 'image/png') {
                    this.$swal({
                        position: "center",
                        icon: "warning",
                        title: "O Ícone precisa ser do tipo PNG",
                        showConfirmButton: true,
                        timer: 6000
                    });
                    return
                }
            }

            this.fileNameIcone = this.uploadIcone.name;

            this.previewIconeImage(files[0]);
        },
        onFileBannerChange(e) {
            let files = e.target.files || e.dataTransfer.files;

            if (!files.length)
                return;

            this.uploadBanner = files[0];
            this.fileNameBanner = this.uploadBanner.name;

            this.previewBannerImage(files[0]);
        },
        previewIconeImage(file) {

            let reader = new FileReader();
            reader.onload = (e) => {
                this.previewIcone = e.target.result;
            };
            reader.readAsDataURL(file)
        },
        previewBannerImage(file) {

            let reader = new FileReader();
            reader.onload = (e) => {
                this.previewBanner = e.target.result;
            };
            reader.readAsDataURL(file)
        },
        removePreviewIcone() {
            this.previewIcone = null;
            this.uploadIcone = null;
            this.storegiftlistcategory.stor_glc_icone = '';
        },
        removePreviewBanner() {
            this.previewBanner = null;
            this.uploadBanner = null;
            this.storegiftlistcategory.stor_glc_banner = '';
        },
    },
    components: {

    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
    height: 120px;
}
</style>
